import { Dialog, Transition } from '@headlessui/react';
import cx from 'clsx';
import { PanelBasic } from 'components/layout/Panel/PanelBasic';
import { makeStyles } from 'lib/makeStyles';
import {
  modalContentTransition,
  modalOverlayTransition
} from 'lib/transitionProps/transitionProps';
import React, { Fragment, useRef } from 'react';

import { ModalProps, StyledProps } from './Modal';
import { ModalContext } from './ModalContext';

const useStyles = makeStyles({
  outerWrapper: (props: ModalProps) => [
    'fixed',
    'inset-0',
    'overflow-hidden',
    'z-public-navigation',
    'h-full w-full',
    {
      'p-1': props.hasOuterPadding
    },
    props.className
  ],
  innerWrapper: (props) => [
    'relative flex items-center',
    {
      'justify-center': props.justifyContent === 'center',
      'justify-start': props.justifyContent === 'start'
    },
    'h-full w-full',
    'z-auto'
  ],
  overlay: (props: ModalProps) => [
    'fixed inset-0',
    {
      overlay: props.open
    }
  ],
  panelBasic: (props) => [
    'z-public-navigation',
    'flex',
    {
      'h-full sm:h-auto': props.hasMobileFullHeight && props.size !== 'full',
      'w-full': props.size !== 'sm',
      'w-[420px]': props.size === 'sm',
      'sm:w-[80vh] max-w-xl': props.size === 'md',
      'sm:w-[800px] max-w-3xl': props.size === 'lg',
      'min-w-full sm:min-w-[420px]': props.size !== 'full',
      'flex-wrap': props.hasPanelFlexWrap,
      'flex-nowrap': !props.hasPanelFlexWrap,
      'overflow-hidden': props.disableOverflowY,
      'overflow-x-hidden': !props.disableOverflowY
    }
  ],
  panelClassName: (props) => props.panelClassName
});

const ModalWrapper: React.ComponentType<ModalProps> = React.memo(
  ({
    className,
    children,
    open,
    onClose,
    toggleState,
    hasMobileFullHeight,
    hasPanelFlexWrap,
    hasOuterPadding,
    panelClassName,
    defaultPadding,
    justifyContent,
    size,
    disableOverflowY
  }) => {
    const styles = useStyles({
      className,
      open: open ?? toggleState.status,
      hasMobileFullHeight,
      hasPanelFlexWrap,
      hasOuterPadding,
      panelClassName,
      justifyContent,
      size,
      disableOverflowY
    } as StyledProps);
    const innerDialogRef = useRef(null);

    return (
      <Transition show={open ?? toggleState?.status} as={Fragment}>
        <Dialog
          id="modal"
          aria-label="modal"
          aria-modal={true}
          initialFocus={innerDialogRef}
          onClose={() => null}
          className={styles.outerWrapper}>
          <ModalContext.Provider value={{ toggleState }}>
            <div className={styles.innerWrapper}>
              <Transition.Child as={Fragment} {...modalOverlayTransition}>
                <Dialog.Overlay className={styles.overlay} onClick={onClose} />
              </Transition.Child>
              <Transition.Child as={Fragment} {...modalContentTransition}>
                <PanelBasic
                  ref={innerDialogRef}
                  className={cx(panelClassName ? styles.panelClassName : styles.panelBasic)}
                  type={hasOuterPadding ? 'popover' : 'screen'}
                  fullHeight={size == 'full'}
                  defaultPadding={defaultPadding}
                  rounded="LG"
                  paddingY="MD"
                  fullWidth={size == 'full'}>
                  {children}
                </PanelBasic>
              </Transition.Child>
            </div>
          </ModalContext.Provider>
        </Dialog>
      </Transition>
    );
  }
);

ModalWrapper.defaultProps = {
  hasMobileFullHeight: true,
  hasPanelFlexWrap: false,
  hasOuterPadding: true,
  size: 'md',
  justifyContent: 'center',
  disableOverflowY: false
};

ModalWrapper.displayName = 'ModalWrapper';

export { ModalWrapper };
